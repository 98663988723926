export * from './challenges.service';
import { ChallengesService } from './challenges.service';
export * from './file.service';
import { FileService } from './file.service';
export * from './portal-api.service';
import { PortalApiService } from './portal-api.service';
export * from './stripe-webhook.service';
import { StripeWebhookService } from './stripe-webhook.service';
export * from './submissions.service';
import { SubmissionsService } from './submissions.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './voucher.service';
import { VoucherService } from './voucher.service';
export * from './workspaces.service';
import { WorkspacesService } from './workspaces.service';
export const APIS = [ChallengesService, FileService, PortalApiService, StripeWebhookService, SubmissionsService, UserService, UsersService, VoucherService, WorkspacesService];
