<ng-container *transloco="let t">
  <div
    #myElement
    class="w-full flex flex-column justify-content-center align-items-center"
  >
    <div class="w-11 md:w-5">
      <p-card>
        <div class="flex justify-content-center">
          <img src="../../../assets/images/PiViDro_Light.svg" width="300" />
        </div>

        <div class="flex justify-content-center mt-6 bg-gray-50 p-4">
          <span class="text-center text-xl">{{ t('text-redeemed') }}</span>
        </div>

        <div class="flex justify-content-center mt-4">
          <img src="../../../assets/images/Join.png" width="300" />
        </div>
      </p-card>
    </div>
  </div>
</ng-container>
