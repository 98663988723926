import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspaceDto } from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { distinctUntilChanged } from 'rxjs';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-upload-step-legal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    RippleModule,
    RouterLink,
    CheckboxModule,
    TranslocoDirective,
    ScrollPanelModule,
    PanelModule,
  ],
  templateUrl: './upload-step-legal.component.html',
  styleUrls: ['./upload-step-legal.component.scss'],
})
export class UploadStepLegalComponent implements OnInit {
  workspace: WorkspaceDto;

  @Input() formGroupName!: string;
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();
  form!: FormGroup;
  actualMonth: string;
  lang = '';

  constructor(
    private workspaceDataService: WorkspaceDataService,
    private rootFormGroup: FormGroupDirective,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.translocoService.langChanges$.subscribe((value) => {
      this.lang = value;
      if (value === 'de') {
        this.actualMonth = Intl.DateTimeFormat('de-DE', {
          month: 'long',
          year: 'numeric',
        }).format(new Date());
      } else {
        this.actualMonth = Intl.DateTimeFormat('en-US', {
          month: 'long',
          year: 'numeric',
        }).format(new Date());
      }
    });

    this.form = this.rootFormGroup.control?.get(
      this.formGroupName
    ) as FormGroup;

    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
      });
  }

  onSubmit() {
    this.form.markAsDirty();
    this.next.emit('');
  }
}
