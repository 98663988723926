import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../footer/footer.component';
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component';

@Component({
  selector: 'sweetpopcorn-voucher',
  standalone: true,
  templateUrl: './voucher.component.html',
  imports: [LanguageSelectorComponent, RouterOutlet, FooterComponent],
  styleUrls: ['./voucher.component.css'],
})
export class VoucherComponent {}
