<ng-container *transloco="let t">
  <div class="surface-card p-4">
    <div class="mt-2">
      <div class="flex flex-column">
        <div class="text-2xl font-bold mb-4">{{ t('impressum-title') }}</div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('company-name') }}</div>
          <p>SweetPopcorn.Studio GmbH</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">
            {{ t('company-register-number') }}
          </div>
          <p>FN 568758 i</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('company-vat-number') }}</div>
          <p>ATU77601034</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('company-address') }}</div>
          <p>Seespitzstraße 8, 5700 Zell am See, Österreich</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('contact-details') }}</div>
          <p>office@sweetpopcorn.studio</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('trade-regulations') }}</div>
          <p>www.ris.bka.gv.at</p>
          <p>{{ t('trade-regulations-paragraph') }}</p>
        </div>

        <div class="mt-2">
          <div class="text-2xl font-bold">{{ t('image-rights') }}</div>
          <p>SweetPopcorn.Studio GmbH</p>
        </div>
      </div>
    </div>
    <div class="flex flex-column sm:mr-0 lg:mr-8">
      <div class="flex flex-row border-bottom-1 text-primary mt-8">
        <div class="flex align-self-center text-white font-bold text-xl mb-2">
          {{ t('cookies-title') }}
        </div>
      </div>
      <div class="mt-2">
        {{ t('cookies-body') }}
      </div>

      <button
        pButton
        pRipple
        type="button"
        [label]="t('btn-cookieSettings')"
        class="p-button-text mt-4 w-10rem"
        icon="pi pi-angle-right"
        iconPos="right"
        (click)="openSettings()"
      ></button>
    </div>
  </div>
</ng-container>
