/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { UserDto } from './user-dto';
import { UploadLimit } from './upload-limit';
import { PrizeDto } from './prize-dto';
import { Visibility } from './visibility';
import { Orientation } from './orientation';


export interface ChallengeDto { 
    id?: string;
    workspaceId?: string;
    title?: string | null;
    titleEn?: string | null;
    description?: string | null;
    descriptionEn?: string | null;
    visibility?: Visibility;
    uploadLimit?: UploadLimit;
    submissionCount?: number;
    submissionItemCount?: number;
    isActive?: boolean;
    isScheduled?: boolean;
    isFinished?: boolean;
    isClosed?: boolean;
    isPhotoAllowed?: boolean;
    isVideoAllowed?: boolean;
    isAudioAllowed?: boolean;
    isTextAllowed?: boolean;
    minTextLength?: number | null;
    maxTextLength?: number | null;
    minAudioVideoDuration?: string | null;
    maxAudioVideoDuration?: string | null;
    photoVideoOrientation?: Orientation;
    maxFileSize?: number | null;
    maxSubmissionSize?: number | null;
    file?: FileDto;
    submissionStartTime?: string | null;
    submissionEndTime?: string;
    prize?: PrizeDto;
    createdBy?: UserDto;
    createdOn?: string;
    lastModifiedOn?: string | null;
    hasWinningSubmission?: boolean;
}

