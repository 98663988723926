import { Component } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'sweetpopcorn-invalid-promo-code',
  standalone: true,
  templateUrl: './invalid-promo-code.component.html',
  styleUrls: ['./invalid-promo-code.component.scss'],
  imports: [CardModule, TranslocoDirective],
})
export class InvalidPromoCodeComponent {}
