import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

function addClarityScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (environment.tracking) {
      const script = document.createElement('script');
      script.setAttribute('cookie-consent', 'tracking');
      script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "jmahleb7ql");`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    }
  });
}

addClarityScript()
  .then()
  .catch((error) => console.error('Script loading failed', error));

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
