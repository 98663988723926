<ng-container *transloco="let t">
  <ng-container *ngIf="challenge">
    <div
      class="flex flex-column justify-content-center align-content-center align-items-center w-full"
    >
      <div
        class="flex flex-column w-11 md:w-10 h-full p-4 surface-card shadow-2 border-round mt-3"
      >
        <div class="flex flex-row justify-content-end w-full pt-2 pr-2">
          <sweetpopcorn-language-selector></sweetpopcorn-language-selector>
        </div>
        <div class="flex flex-row justify-content-center mt-2 mb-2">
          <img
            *ngIf="challenge?.file?.url; else defaultWorkspace"
            [src]="challenge?.file?.url"
            alt="uplaod-form-heade"
            class="w-full md:w-4 xl:w-4"
            (click)="openLink(workspaceUrl)"
          />
          <ng-template #defaultWorkspace>
            <img
              src="./assets/images/Upload.png"
              alt="uplaod-form-heade"
              class="w-4"
              (click)="openLink(workspaceUrl)"
            />
          </ng-template>
        </div>
        <div class="card">
          <ng-container [formGroup]="wizardForm">
            <p-tabView styleClass="tabview-custom" [(activeIndex)]="index">
              <p-tabPanel [disabled]="true">
                <ng-template pTemplate="header">
                  <span>{{ t('label-challenge') }}</span>
                </ng-template>
                <sweetpopcorn-start-challenge
                  (next)="onNext($event)"
                ></sweetpopcorn-start-challenge>
              </p-tabPanel>
              <p-tabPanel [disabled]="true">
                <ng-template pTemplate="header">
                  <span>{{ t('step2-name') }}</span>
                </ng-template>
                <sweetpopcorn-challenge-step-legal
                  formGroupName="legal"
                  (next)="onNext($event)"
                ></sweetpopcorn-challenge-step-legal>
              </p-tabPanel>
              <p-tabPanel header="Header II" [disabled]="true">
                <ng-template pTemplate="header">
                  <span>{{ t('step1-name') }}</span>
                </ng-template>
                <sweetpopcorn-challenge-step-info
                  formGroupName="info"
                  (next)="onNext($event)"
                ></sweetpopcorn-challenge-step-info>
              </p-tabPanel>
              <p-tabPanel header="Header III" [disabled]="true">
                <ng-template pTemplate="header">
                  <span>{{ t('step3-name') }}</span>
                </ng-template>
                <sweetpopcorn-challenge-step-upload
                  [uploadCode]="uploadCode"
                  (next)="onNext($event)"
                ></sweetpopcorn-challenge-step-upload>
              </p-tabPanel>
              <p-tabPanel header="Header IIII" [disabled]="true">
                <ng-template pTemplate="header">
                  <span>{{ t('step4-name') }}</span>
                </ng-template>
                <sweetpopcorn-challenge-step-contract
                  (next)="onNext($event)"
                  [workspaceId]="workspaceSlug"
                ></sweetpopcorn-challenge-step-contract>
              </p-tabPanel>
            </p-tabView>
          </ng-container>
        </div>
      </div>
    </div>
    <sweetpopcorn-footer></sweetpopcorn-footer>
  </ng-container>
</ng-container>
