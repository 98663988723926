import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, provideRouter } from '@angular/router';
import { ApiConnectorConfiguration } from '@sweetpopcorn/api-connector';
import { environment } from '../environments/environment';
import { ChallengeWizardComponent } from './challenge/challenge-wizard/challenge-wizard.component';
import { UploadDecisionComponent } from './decision/upload-decision.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent } from './not-found/not-found/not-found.component';
import { UploadWizardComponent } from './open-upload/upload-wizard/upload-wizard.component';
import { OverviewComponent } from './overview/overview.component';
import { InvalidPromoCodeComponent } from './redeem/invalidPromoCode/invalid-promo-code.component';
import { RedeemedComponent } from './redeem/redeemed/redeemed.component';
import { VoucherDevalueComponent } from './redeem/voucher-devalue/voucher-devalue.component';
import { VoucherComponent } from './redeem/voucher/voucher.component';
import { TranslocoRootModule } from './transloco-root.module';

const appRoutes: Route[] = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
  },
  {
    path: 'prize',
    component: VoucherComponent,
    children: [
      {
        path: 'use',
        component: VoucherDevalueComponent,
        pathMatch: 'full',
      },
      { path: 'invalidCode', component: InvalidPromoCodeComponent },
      { path: 'redeemed', component: RedeemedComponent },
    ],
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: ':workspaceId',
    component: OverviewComponent,
  },
  {
    path: ':workspaceId/decision',
    component: UploadDecisionComponent,
  },
  {
    path: ':workspaceId/challenge/:challengeId',
    component: ChallengeWizardComponent,
  },
  {
    path: ':workspaceId/upload',
    component: UploadWizardComponent,
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    [
      importProvidersFrom(HttpClientModule),
      importProvidersFrom(TranslocoRootModule),
      importProvidersFrom(BrowserAnimationsModule),
    ],
    {
      provide: ApiConnectorConfiguration,
      useValue: new ApiConnectorConfiguration({
        basePath: environment.production
          ? environment.basePath
          : window.location.origin,
      }),
    },
  ],
};
