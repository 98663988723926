import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengeDto,
  ChallengesService,
  WorkspaceDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, Subject, filter, map, switchMap, tap } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-upload-decision',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    RouterLink,
    FooterComponent,
    TranslocoDirective,
    LanguageSelectorComponent,
    SkeletonModule,
  ],
  templateUrl: './upload-decision.component.html',
  styleUrls: ['./upload-decision.component.scss'],
})
export class UploadDecisionComponent implements OnInit {
  $workspace: Observable<WorkspaceDto>;
  $challenges: Observable<ChallengeDto[]>;
  reloadSubj = new Subject<void>();
  lang = '';

  nextPage = 1;
  nextPageSize = 12;
  hasMoreData = true;
  totalCount = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workspaceService: WorkspacesService,
    private challengesService: ChallengesService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.$workspace = this.activatedRoute.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id),
      switchMap((workspaceId) =>
        this.workspaceService.getWorkspaceInfoById(workspaceId)
      ),
      untilDestroyed(this)
    );

    this.$workspace.pipe(untilDestroyed(this)).subscribe({
      error: async () => {
        await this.router.navigate(['/', 'not-found'], {
          queryParams: { reason: 'workspace-not-found' },
        });
      },
    });

    this.$challenges = this.$workspace.pipe(
      map((workspace) => workspace),
      switchMap((workspace) =>
        this.challengesService.getActiveChallengesFromWorkspace(
          workspace?.id,
          this.nextPage,
          this.nextPageSize
        )
      ),
      tap((data) => {
        this.hasMoreData = data.hasNextPage;
        this.totalCount = data.totalCount;
      }),
      map((data) => data.items)
    );

    this.translocoService.langChanges$.subscribe((value) => {
      this.lang = value;
    });
  }

  mailTo(mail: string) {
    const email = 'mailto:' + mail; // add the links to body
    window.location.href = email;
  }
  phoneTo(phone: string) {
    const tel = 'tel:' + phone; // add the links to body
    window.location.href = tel;
  }
  openLink(url: string) {
    window.open(url ? url : '/', '_blank');
  }
}
