import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'sweetpopcorn-impressum',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, ButtonModule, RippleModule],
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent {
  openSettings() {
    (window as any).cookieconsent.openPreferencesCenter();
  }
}
