import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateAge(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const d = new Date(control.value);

    let age = (new Date().getTime() - d.getTime()) / 1000;
    age /= 60 * 60 * 24;

    age = Math.abs(Math.round(age / 365.25));

    const isValid = age > 18;
    return isValid ? null : { notAllowed: { value: 'toYoung' } };
  };
}
