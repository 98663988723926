/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Visibility {
    Public = 'Public',
    NotListed = 'NotListed',
    Private = 'Private'
};

