<ng-container *transloco="let t">
  <div class="flex flex-row justify-content-center mt-4 gap-4">
    <div>
      Powered by <a href="https://pividro.com" target="_blank">Pividro.com</a>
    </div>
    <div>
      <a [routerLink]="['/impressum']" target="_blank">{{
        t('impressum-title')
      }}</a>
    </div>
  </div>
</ng-container>
