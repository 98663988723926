<ng-container *transloco="let t">
  <div class="flex flex-column w-full p-0 md:p-4">
    <div>
      <p-panel [header]="t('step4-desc')">
        <div class="flex flex-row justify-content-center">
          <div class="flex flex-column align-items-center">
            <div class="text-2xl">{{ t('final-siteTitle') }}</div>
            <div class="m-4 text-center line-height-2">
              {{ t('final-siteHeader') }}
            </div>
            <div>
              <button
                pButton
                pRipple
                class="w-auto p-button-warning"
                (click)="downloadContract()"
              >
                {{ t('btn-contract') }}
              </button>
            </div>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="flex flex-column gap-4 mt-4 mb-4 p-4">
      <button
        pButton
        pRipple
        label="{{ t('btn-done') }}"
        class="w-auto"
        (click)="goHome()"
      ></button>
    </div>
  </div>
</ng-container>
