import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { RippleModule } from 'primeng/ripple';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@Component({
  selector: 'sweetpopcorn-challenge-step-contract',
  standalone: true,
  imports: [PanelModule, TranslocoDirective, ButtonModule, RippleModule],
  templateUrl: './challenge-step-contract.component.html',
  styleUrls: ['./challenge-step-contract.component.scss'],
})
export class ChallengeStepContractComponent {
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();
  @Input() workspaceId = '';
  constructor(
    private workspaceDataService: WorkspaceDataService,
    private router: Router
  ) {}
  downloadContract() {
    window.open(this.workspaceDataService.contractUrl);
  }

  nextPage() {
    this.next.emit('next');
  }
  async goHome() {
    await this.router.navigate(['/', this.workspaceId]);
  }
}
