import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChallengeDto,
  ChallengesService,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StepsModule } from 'primeng/steps';
import { TabViewModule } from 'primeng/tabview';
import { filter, map, switchMap } from 'rxjs';
import { validateAge } from '../../Validators/AgeValidator';
import { FooterComponent } from '../../footer/footer.component';
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component';
import { ChallengeDataService } from '../../services/challenge-data.service';
import { WorkspaceDataService } from '../../services/workspace-data.service';
import { ChallengeOverviewComponent } from '../challenge-overview/challenge-overview.component';
import { ChallengeStepContractComponent } from '../challenge-step-contract/challenge-step-contract.component';
import { ChallengeStepInfoComponent } from '../challenge-step-info/challenge-step-info.component';
import { ChallengeStepLegalComponent } from '../challenge-step-legal/challenge-step-legal.component';
import { ChallengeStepPrizeComponent } from '../challenge-step-prize/challenge-step-prize.component';
import { ChallengeStepUploadComponent } from '../challenge-step-upload/challenge-step-upload.component';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-challenge-wizard',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StepsModule,
    FooterComponent,
    LanguageSelectorComponent,
    ReactiveFormsModule,
    TabViewModule,
    ChallengeStepLegalComponent,
    ChallengeStepInfoComponent,
    ChallengeStepUploadComponent,
    ChallengeStepPrizeComponent,
    TranslocoDirective,
    ChallengeOverviewComponent,
    ChallengeStepContractComponent,
  ],
  templateUrl: './challenge-wizard.component.html',
  styleUrls: ['./challenge-wizard.component.scss'],
})
export class ChallengeWizardComponent implements OnInit {
  workspaceId: string;
  workspaceSlug: string;
  workspaceUrl = '';
  challenge: ChallengeDto;
  wizardForm!: FormGroup;
  uploadCode = '';
  index = 0;
  // items: MenuItem[];

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private workspaceService: WorkspacesService,
    private challengesService: ChallengesService,
    private challengeDataService: ChallengeDataService,
    private workspaceDataService: WorkspaceDataService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.wizardForm = this.fb.group({
      legal: this.fb.group({
        agbAccepted1: [true, [Validators.required, Validators.requiredTrue]],
        agbAccepted2: [false, [Validators.required, Validators.requiredTrue]],
        newsletter: [false],
      }),
      info: this.fb.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        dateOfBirth: [
          '',
          [Validators.required, validateAge(), Validators.minLength(10)],
        ],
        customerReference: [''],
      }),
    });

    this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => paramMap.get('workspaceId')),
        filter((id) => !!id),
        switchMap((workspaceId) =>
          this.workspaceService.getWorkspaceInfoById(workspaceId)
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (data) => {
          this.workspaceDataService.setWorkspace(data);
          this.workspaceId = data.id;
          this.workspaceUrl = data.contactUrl;
          this.workspaceSlug = data.slug;
        },
        error: async () => {
          await this.router.navigate(['/', 'not-found'], {
            queryParams: { reason: 'workspace-not-found' },
          });
        },
      });

    this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => paramMap.get('challengeId')),
        filter((id) => !!id),
        switchMap((challengeId) =>
          this.challengesService.getChallengeInfoById(challengeId)
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (data) => {
          this.challengeDataService.setChallenge(data);
          if (data.isActive) {
            this.challenge = data;
            this.titleService.setTitle(`Challenge - ${this.challenge?.title}`);
          } else {
            this.router.navigate(['/', 'not-found'], {
              queryParams: { reason: 'challenge-not-available' },
            });
          }
        },
        error: async () => {
          await this.router.navigate(['/', 'not-found'], {
            queryParams: { reason: 'challenge-not-found' },
          });
        },
      });
  }

  openLink(url: string) {
    window.open(url ? url : '/', '_blank');
  }

  onNext($event: string) {
    this.uploadCode = $event;
    this.index++;
  }
}
