import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChallengeDto, WorkspaceDto } from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { distinctUntilChanged } from 'rxjs';
import { ChallengeDataService } from '../../services/challenge-data.service';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-challenge-step-legal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    TranslocoDirective,
    PanelModule,
    ScrollPanelModule,
  ],
  templateUrl: './challenge-step-legal.component.html',
  styleUrls: ['./challenge-step-legal.component.scss'],
})
export class ChallengeStepLegalComponent implements OnInit {
  workspace: WorkspaceDto;
  challengeId: string;
  challenge: ChallengeDto;

  @Input() formGroupName!: string;
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();
  form!: FormGroup;
  actualMonth: string;
  lang = '';

  constructor(
    private workspaceDataService: WorkspaceDataService,
    private challengeDataService: ChallengeDataService,
    private rootFormGroup: FormGroupDirective,
    private translocoService: TranslocoService
  ) {
    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
      });

    this.challengeDataService.challenge$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((challenge) => {
        this.challengeId = challenge.id;
        this.challenge = challenge;
      });
  }

  ngOnInit() {
    this.translocoService.langChanges$.subscribe((value) => {
      this.lang = value;
      if (value === 'de') {
        this.actualMonth = Intl.DateTimeFormat('de-DE', {
          month: 'long',
          year: 'numeric',
        }).format(new Date());
      } else {
        this.actualMonth = Intl.DateTimeFormat('en-US', {
          month: 'long',
          year: 'numeric',
        }).format(new Date());
      }
    });

    this.form = this.rootFormGroup.control?.get(
      this.formGroupName
    ) as FormGroup;
  }

  onSubmit() {
    this.form.markAsDirty();
    this.next.emit('');
  }
}
