import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Language,
  SubmissionsService,
  WorkspaceDto,
} from '@sweetpopcorn/api-connector';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { distinctUntilChanged, switchMap } from 'rxjs';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-upload-step-info',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    RippleModule,
    RouterLink,
    TranslocoDirective,
    TooltipModule,
    ToastModule,
    CheckboxModule,
    CalendarModule,
  ],
  templateUrl: './upload-step-info.component.html',
  styleUrls: ['./upload-step-info.component.scss'],
  providers: [MessageService],
})
export class UploadStepInfoComponent implements OnInit {
  workspace: WorkspaceDto;
  @Input() formGroupName!: string;
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();
  form!: FormGroup;

  hasPassword = false;
  messageText: { header: string; message: string };
  defaultDate = new Date('01.01.2006');

  constructor(
    private workspaceDataService: WorkspaceDataService,
    private submissionService: SubmissionsService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private rootFormGroup: FormGroupDirective,
    private config: PrimeNGConfig
  ) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control?.get(
      this.formGroupName
    ) as FormGroup;

    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
        this.hasPassword = workspace.hasPassword;
      });

    this.translocoService.langChanges$
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.translocoService.selectTranslate('label-name'))
      )
      .subscribe(() => this.setMessage());

    this.translocoService.langChanges$.subscribe((value) =>
      this.translateNgPrime(value)
    );
  }

  onSubmit() {
    let lang = Language.De;

    if (this.translocoService.getActiveLang() == 'en') {
      lang = Language.En;
    }

    // Get the date from the form
    const dateOfBirth = new Date(this.form.get('dateOfBirth').value);

    // Adjust for the timezone offset to ensure the date is correct in local time
    const adjustedDate = new Date(
      dateOfBirth.getTime() - dateOfBirth.getTimezoneOffset() * 60000
    );

    // Convert to ISO string and take the first 10 characters (YYYY-MM-DD)
    const isoDate = adjustedDate.toISOString().substring(0, 10);

    this.submissionService
      .submitSubmission({
        workspaceId: this.workspace?.id,
        password: this.form.get('password').value,
        firstName: this.form.get('firstname').value,
        lastName: this.form.get('lastname').value,
        email: this.form.get('email').value,
        customerReference: this.form.get('customerReference').value,
        dateOfBirth: isoDate,
        language: lang,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: async (result) => {
          this.next.emit(result.code);
          this.workspaceDataService.submission = result;

          // await this.router.navigate(
          //   ['/', this.workspace.slug, 'upload', 'legal'],
          //   { queryParams: { code: result?.code } }
          // );
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: this.messageText.header,
            detail: this.messageText.message,
          });
          console.error(err);
        },
      });
  }

  private setMessage() {
    this.messageText = {
      header: this.translocoService.translate('info-message-error-title'),
      message: this.translocoService.translate('info-message-error-message'),
    };
  }
  private translateNgPrime(value: string) {
    if (value === 'de') {
      this.config.setTranslation({
        dayNames: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fri', 'Sam'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: [
          'Jannuar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dez',
        ],
      });
    } else {
      this.config.setTranslation({
        dayNames: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      });
    }
  }
}
