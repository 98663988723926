import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspaceDto, WorkspacesService } from '@sweetpopcorn/api-connector';
import { StepsModule } from 'primeng/steps';
import { TabViewModule } from 'primeng/tabview';
import { filter, map, switchMap } from 'rxjs';
import { validateAge } from '../../Validators/AgeValidator';
import { FooterComponent } from '../../footer/footer.component';
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component';
import { WorkspaceDataService } from '../../services/workspace-data.service';
import { UploadStepContractComponent } from '../upload-step-contract/upload-step-contract.component';
import { UploadStepInfoComponent } from '../upload-step-info/upload-step-info.component';
import { UploadStepLegalComponent } from '../upload-step-legal/upload-step-legal.component';
import { UploadStepPrizeComponent } from '../upload-step-prize/upload-step-prize.component';
import { UploadStepUploadComponent } from '../upload-step-upload/upload-step-upload.component';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-upload-wizard',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    StepsModule,
    FooterComponent,
    LanguageSelectorComponent,
    TranslocoDirective,
    TabViewModule,
    UploadStepLegalComponent,
    UploadStepInfoComponent,
    UploadStepUploadComponent,
    UploadStepPrizeComponent,
    ReactiveFormsModule,
    UploadStepContractComponent,
  ],
  templateUrl: './upload-wizard.component.html',
  styleUrls: ['./upload-wizard.component.scss'],
})
export class UploadWizardComponent implements OnInit {
  wizardForm!: FormGroup;
  index = 0;
  workspace: WorkspaceDto;
  // items: MenuItem[];
  id: string;
  uploadCode = '';

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workspaceService: WorkspacesService,
    private workspaceDataService: WorkspaceDataService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.wizardForm = this.fb.group({
      legal: this.fb.group({
        agbAccepted1: [true, [Validators.required, Validators.requiredTrue]],
        agbAccepted2: [false, [Validators.required, Validators.requiredTrue]],
        newsletter: [false],
      }),
      info: this.fb.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        dateOfBirth: [
          '',
          [Validators.required, validateAge(), Validators.minLength(10)],
        ],
        customerReference: [''],
        password: [''],
      }),
    });

    this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => paramMap.get('workspaceId')),
        filter((id) => !!id),
        switchMap((workspaceId) =>
          this.workspaceService.getWorkspaceInfoById(workspaceId)
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (data) => {
          this.workspaceDataService.setWorkspace(data);
          this.workspace = data;
          this.titleService.setTitle(`Upload - ${this.workspace?.name}`);
        },
        error: async () => {
          await this.router.navigate(['/', 'not-found'], {
            queryParams: { reason: 'workspace-not-found' },
          });
        },
      });

    // this.translocoService.langChanges$
    //   .pipe(
    //     untilDestroyed(this),
    //     switchMap(() => this.translocoService.selectTranslate('label-name'))
    //   )
    //   .subscribe(() => this.setItems());
  }

  onNext($event: string) {
    this.uploadCode = $event;
    this.index++;
  }

  // setItems() {
  //   this.items = [
  //     {
  //       label: this.translocoService.translate('step1-name'),
  //       routerLink: 'info',
  //     },
  //     // {
  //     //   label: this.translocoService.translate('step2-name'),
  //     //   routerLink: 'legal',
  //     // },
  //     {
  //       label: this.translocoService.translate('step3-name'),
  //       routerLink: 'upload',
  //     },
  //     {
  //       label: this.translocoService.translate('step4-name'),
  //       routerLink: 'prize',
  //     },
  //   ];
  // }
}
