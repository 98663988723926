<div>
  <span class="cursor-pointer" (click)="updateLanguage(selectedLanguage)">{{
    selectedLanguage | uppercase
  }}</span>
  <!--  <p-dropdown-->
  <!--    [options]="languageOptions"-->
  <!--    (onChange)="updateLanguage($event)"-->
  <!--    class="w-10rem"-->
  <!--    [(ngModel)]="currentLang"-->
  <!--    [autoDisplayFirst]="false"-->
  <!--  >-->
  <!--    <ng-template pTemplate="selectedItem">-->
  <!--      <div class="flex flex-row justify-content-between">-->
  <!--        <img [src]="currentLang.flag" style="width: 18px" />-->
  <!--        <div class="uppercase ml-2">{{ currentLang.code }}</div>-->
  <!--      </div>-->
  <!--    </ng-template>-->

  <!--    <ng-template let-lang pTemplate="item">-->
  <!--      <div class="flex flex-row justify-content-around">-->
  <!--        <img [src]="lang.flag" style="width: 18px" />-->
  <!--        <div class="uppercase">{{ lang.code }}</div>-->
  <!--      </div>-->
  <!--    </ng-template>-->
  <!--  </p-dropdown>-->
</div>
