/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';


export interface SubmitSubmissionCommand { 
    workspaceId?: string;
    password?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    customerReference?: string | null;
    dateOfBirth?: string;
    language?: Language;
}

