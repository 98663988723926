<ng-container *transloco="let t">
  <div
    #myElement
    class="w-full flex flex-column justify-content-center align-items-center"
  >
    <div class="w-10 md:w-5">
      <p-card>
        <div class="flex justify-content-center">
          <img src="../../../assets/images/PiViDro_Light.svg" width="300" />
        </div>
        <div *ngIf="valid; else invalidBlock">
          <div class="flex flex-column ml-5 mt-4">
            <div class="text-2xl">{{ t('title-redeem') }}</div>
            <div
              *ngIf="lang === 'de'; else enBlock"
              class="flex flex-column mt-2"
            >
              <label class="mt-2 font-bold" for="prizedesc"
                >{{ t('label-prize') }}:</label
              >
              <p-scrollPanel
                id="prizedesc"
                [style]="{ width: '100%', height: '150px' }"
                class="mt-2"
              >
                {{ voucher?.voucherPrize }}
              </p-scrollPanel>
            </div>
            <ng-template #enBlock class="flex flex-column">
              <label class="mt-2 font-bold" for="prizedescEn"
                >{{ t('label-prize') }}:</label
              >
              <p-scrollPanel
                id="prizedescEn"
                [style]="{ width: '100%', height: '150px' }"
                class="mt-2"
              >
                {{ voucher?.voucherPrizeEn }}
              </p-scrollPanel>
            </ng-template>
          </div>
          <div
            class="flex justify-content-center w-full"
            (touchmove)="touchMove($event)"
            (mousemove)="mouseMove($event)"
            (mouseup)="finished($event)"
            (touchend)="finished($event)"
          >
            <div #background id="background">
              <span class="slide-text">{{ t('label-swipe') }}</span>
              <div #slider id="slider" (pointerdown)="unlock($event)">
                <i
                  #lock
                  id="locker"
                  class="pi pi-angle-right"
                  style="font-size: 2rem; color: white"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <ng-template #invalidBlock>
          <div *ngIf="voucher?.validatedOn" class="mt-4">
            <div class="text-xl bg-gray-50 p-4">
              <span
                >{{ t('label-validated') }}:
                {{ voucher?.validatedOn | date }}</span
              >
            </div>

            <div class="flex justify-content-center mt-4">
              <img src="../../../assets/images/Join.png" width="300" />
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</ng-container>
