<div class="flex flex-column gap-4 w-full">
  <div class="flex bg-gray-800" style="min-height: 66px">
    <div class="flex align-items-center w-8 md:w-10"></div>

    <div
      class="flex flex-row justify-content-end align-items-center w-full fontWhite mr-4"
    >
      <div>
        <sweetpopcorn-language-selector></sweetpopcorn-language-selector>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <sweetpopcorn-footer class="-mt-4"></sweetpopcorn-footer>
</div>
