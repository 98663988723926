import { Injectable } from '@angular/core';
import {
  SubmissionWithCodeDto,
  WorkspaceDto,
} from '@sweetpopcorn/api-connector';
import { BehaviorSubject, Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceDataService {
  private workspace: Subject<WorkspaceDto> = new BehaviorSubject<WorkspaceDto>(
    null
  );

  submission: SubmissionWithCodeDto;
  contractUrl: string;

  get workspace$() {
    return this.workspace
      .asObservable()
      .pipe(filter((workspace) => !!workspace));
  }

  setWorkspace(workspace: WorkspaceDto) {
    this.workspace.next(workspace);
  }
}
