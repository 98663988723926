import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ApiConnectorApiModule,
  SubscriptionStatus,
  WorkspaceDto,
  WorkspacesService,
} from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, catchError, filter, map, switchMap, tap } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-overview',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    ApiConnectorApiModule,
    ButtonModule,
    RippleModule,
    RouterLink,
    FooterComponent,
    TranslocoDirective,
    LanguageSelectorComponent,
    SkeletonModule,
  ],
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  $workspace: Observable<WorkspaceDto>;
  lang = 'de';

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workspaceService: WorkspacesService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.translocoService.langChanges$.subscribe(
      (value) => (this.lang = value)
    );

    this.$workspace = this.activatedRoute.paramMap.pipe(
      map((paramMap) => paramMap.get('workspaceId')),
      filter((id) => !!id),
      switchMap((workspaceId) =>
        this.workspaceService.getWorkspaceInfoById(workspaceId)
      ),
      untilDestroyed(this),
      tap((workspaceDto: WorkspaceDto) => {
        if (
          workspaceDto.subscriptionStatus === SubscriptionStatus.Active ||
          workspaceDto.subscriptionStatus === SubscriptionStatus.Trialing
        ) {
          return;
        } else {
          this.router.navigate(['/', 'not-found'], {
            queryParams: { reason: 'unpaid' },
          });
        }
      }),
      catchError(() => {
        this.router.navigate(['/', 'not-found'], {
          queryParams: { reason: 'workspace-not-found' },
        });
        throw 'workspace not found';
      })
    );

    this.$workspace.pipe(untilDestroyed(this)).subscribe((workSpaceDto) => {
      this.titleService.setTitle(`${workSpaceDto.name}`);
    });
  }
  mailTo(mail: string) {
    const email = 'mailto:' + mail; // add the links to body
    window.location.href = email;
  }
  phoneTo(phone: string) {
    const tel = 'tel:' + phone; // add the links to body
    window.location.href = tel;
  }
  openLink(url: string) {
    window.open(url ? url : '/', '_blank');
  }
}
