/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { Orientation } from './orientation';


export interface SubmissionItemDto { 
    id?: string;
    submissionId?: string;
    width?: number | null;
    height?: number | null;
    rating?: number;
    contentType?: string | null;
    contentLength?: number;
    orientation?: Orientation;
    createdOn?: string;
    lastModifiedOn?: string | null;
    isWinningSubmission?: boolean;
    file?: FileDto;
    thumbnail?: FileDto;
}

