import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspaceDto } from '@sweetpopcorn/api-connector';
import {
  FileUpload,
  FileUploadEvent,
  FileUploadModule,
} from 'primeng/fileupload';
import { RippleModule } from 'primeng/ripple';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-upload-step-upload',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FileUploadModule,
    RippleModule,
    TranslocoDirective,
  ],
  templateUrl: './upload-step-upload.component.html',
  styleUrls: ['./upload-step-upload.component.scss'],
})
export class UploadStepUploadComponent implements OnInit {
  uploadedFiles: any[] = [];
  workspace: WorkspaceDto;
  uploading = false;
  mobile = false;
  basePath = environment.basePath;

  @ViewChild(FileUpload) fileUploader: FileUpload;

  @Input()
  uploadCode: string;
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private workspaceDataService: WorkspaceDataService // private route: ActivatedRoute, // private router: Router
  ) {}

  ngOnInit() {
    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
      });

    this.mobile = window?.innerWidth <= 530;
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this), debounceTime(150))
      .subscribe(() => {
        this.mobile = window?.innerWidth <= 830;
      });
  }

  onUpload(event: FileUploadEvent) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  upload() {
    this.uploading = true;
    this.fileUploader.upload();
    this.fileUploader.onUpload
      .pipe(untilDestroyed(this))
      .subscribe(async (value) => {
        this.onSubmit();
        this.workspaceDataService.contractUrl = (<HttpResponse<any>>(
          value.originalEvent
        )).body.contractFile.url;
      });
  }

  onSubmit() {
    this.uploading = false;
    this.next.emit('');
  }
}
