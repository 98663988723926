<ng-container *transloco="let t">
  <ng-container *ngIf="$workspace | async as workspace; else loading">
    <div
      class="flex flex-column justify-content-center align-content-center align-items-center w-full mt-3 h-full"
    >
      <div
        class="flex flex-column w-11 md:w-10 p-4 surface-card shadow-2 border-round"
      >
        <div class="flex flex-row justify-content-end w-full">
          <sweetpopcorn-language-selector></sweetpopcorn-language-selector>
        </div>
        <div class="flex flex-row justify-content-center mt-2 mb-2">
          <img
            *ngIf="workspace?.file?.url; else defaultWorkspace"
            [src]="workspace?.file?.url"
            (click)="openLink(workspace?.contactUrl)"
            alt="uplaod-form-header"
            class="w-full md:w-5"
          />

          <ng-template #defaultWorkspace>
            <img
              src="./assets/images/Upload.png"
              alt="uplaod-form-header"
              class="w-5"
              (click)="openLink(workspace?.contactUrl)"
            />
          </ng-template>
        </div>

        <div class="flex flex-column gap-3 mt-4">
          <div class="text-2xl text-center">{{ t('title-shareWin') }}</div>
          <div class="text-500 font-medium text-center">
            {{ t('subtitle-shareWin') }}
          </div>
          <div class="text-xl text-center">
            {{
              lang === 'de' ? workspace.prize.title : workspace.prize.titleEn
            }}
          </div>
          <div>
            <div class="flex flex-column gap-4 mt-2">
              <button
                [routerLink]="['/', workspace.slug, 'upload']"
                pButton
                pRipple
                label="{{ t('btn-share') }}"
                class="w-auto"
              ></button>
            </div>
            <div class="flex flex-row justify-content-center flex-wrap mt-2">
              <div class="text-500 text-xs md:text-base">
                {{ workspace?.name }} |
              </div>
              <div
                class="text-500 text-xs md:text-base cursor-pointer"
                (click)="mailTo(workspace?.contactMail)"
              >
                &nbsp;{{ workspace?.contactMail }} &nbsp;
              </div>
              <div
                class="text-500 text-xs md:text-base cursor-pointer"
                *ngIf="workspace?.contactPhone"
                (click)="phoneTo(workspace?.contactPhone)"
              >
                | {{ workspace?.contactPhone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<sweetpopcorn-footer></sweetpopcorn-footer>

<ng-template #loading>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <div class="flex mb-3">
      <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
      <div>
        <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton height=".5rem"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="150px"></p-skeleton>
    <div class="flex justify-content-between mt-3">
      <p-skeleton width="4rem" height="2rem"></p-skeleton>
      <p-skeleton width="4rem" height="2rem"></p-skeleton>
    </div>
  </div>

  <div class="border-round border-1 surface-border p-4 surface-card">
    <ul class="m-0 p-0 list-none">
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li class="mb-3">
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
      <li>
        <div class="flex">
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
