<ng-container *transloco="let t">
  <p-toast></p-toast>
  <div class="flex flex-column w-full p-4">
    <div class="mb-4">
      {{ t('step1-desc') }}
    </div>
    <form [formGroup]="form" class="flex flex-column flex-grow-1">
      <div class="flex-auto p-fluid">
        <div class="mb-4">
          <label for="firstname" class="block font-medium text-900 mb-2"
            >{{ t('label-firstname') }} *</label
          >
          <input
            id="firstname"
            name="firstname"
            type="text"
            pInputText
            formControlName="firstname"
          />
        </div>
        <div class="flex flex-column justify-content-around gap-4 mb-4">
          <div class="w-full">
            <label for="lastname" class="block font-medium text-900 mb-2"
              >{{ t('label-lastname') }} *</label
            >
            <input
              id="lastname"
              name="lastname"
              type="text"
              pInputText
              formControlName="lastname"
            />
          </div>
          <div class="w-full">
            <label for="email" class="block font-medium text-900 mb-2"
              >{{ t('label-email') }} *</label
            >
            <input
              id="email"
              name="email"
              type="email"
              pInputText
              formControlName="email"
            />
          </div>
          <div class="w-full">
            <label for="dateOfBirth" class="block font-medium text-900 mb-2"
              >{{ t('label-dateOfBirth') }} *</label
            >
            <p-calendar
              id="dateOfBirth"
              dateFormat="dd.mm.yy"
              formControlName="dateOfBirth"
            ></p-calendar>
            <span
              *ngIf="
                form.get('dateOfBirth').invalid && form.get('dateOfBirth').value
              "
              class="text-red-600"
            >
              {{ t('date-validation') }}</span
            >
          </div>
          <!--          <div class="w-full">-->
          <!--            <label-->
          <!--              for="customerReference"-->
          <!--              class="block font-medium text-900 mb-2"-->
          <!--              >{{ t('label-referece') }}</label-->
          <!--            >-->
          <!--            <input-->
          <!--              id="customerReference"-->
          <!--              type="text"-->
          <!--              pInputText-->
          <!--              formControlName="customerReference"-->
          <!--              tooltipPosition="top"-->
          <!--              [pTooltip]="t('reference-tooltip')"-->
          <!--            />-->
          <!--          </div>-->
        </div>
        <!--        <div class="w-full" *ngIf="hasPassword">-->
        <!--          <label for="password" class="block font-medium text-900 mb-2">{{-->
        <!--            t('label-password')-->
        <!--          }}</label>-->
        <!--          <input-->
        <!--            id="password"-->
        <!--            type="password"-->
        <!--            pInputText-->
        <!--            formControlName="password"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </form>
    <div class="flex flex-column gap-4 mt-4 mb-4 p-4">
      <button
        pButton
        pRipple
        label="{{ t('btn-next') }}"
        class="w-auto"
        [disabled]="!form.valid"
        (click)="onSubmit()"
      ></button>
    </div>
  </div>
</ng-container>
