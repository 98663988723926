/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkspaceRole } from './workspace-role';
import { FileDto } from './file-dto';
import { SubscriptionStatus } from './subscription-status';
import { UserDto } from './user-dto';
import { Country } from './country';
import { UploadLimit } from './upload-limit';
import { PrizeDto } from './prize-dto';


export interface WorkspaceWithBillingInfoDto { 
    id?: string;
    slug?: string | null;
    name?: string | null;
    nameEn?: string | null;
    description?: string | null;
    descriptionEn?: string | null;
    uploadLimit?: UploadLimit;
    contactUrl?: string | null;
    contactMail?: string | null;
    contactPhone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: Country;
    companyName?: string | null;
    companyVat?: string | null;
    companyDataProtectionUrl?: string | null;
    stripeSubscriptionId?: string | null;
    stripeSessionId?: string | null;
    subscriptionStatus?: SubscriptionStatus;
    subscriptionEndDate?: string | null;
    isInTrialMode?: boolean;
    readonly isLocked?: boolean;
    trialStartOn?: string | null;
    trialEndsOn?: string | null;
    hasPassword?: boolean;
    submissionCount?: number;
    submissionItemCount?: number;
    prize?: PrizeDto;
    file?: FileDto;
    owner?: UserDto;
    createdBy?: UserDto;
    createdOn?: string;
    lastModifiedOn?: string | null;
    currentUserRole?: WorkspaceRole;
}

