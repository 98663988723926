import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@Component({
  selector: 'sweetpopcorn-landing',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    TranslocoDirective,
    LanguageSelectorComponent,
    RouterLink,
  ],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {}
