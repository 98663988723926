/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';


export interface PrizeDto { 
    id?: string;
    title?: string | null;
    titleEn?: string | null;
    description?: string | null;
    descriptionEn?: string | null;
    url?: string | null;
    image?: FileDto;
}

