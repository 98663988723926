import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChallengeDto, WorkspaceDto } from '@sweetpopcorn/api-connector';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import {
  FileUpload,
  FileUploadEvent,
  FileUploadModule,
} from 'primeng/fileupload';
import { RippleModule } from 'primeng/ripple';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChallengeDataService } from '../../services/challenge-data.service';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-challenge-step-upload',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    RippleModule,
    SharedModule,
    TranslocoDirective,
  ],
  templateUrl: './challenge-step-upload.component.html',
  styleUrls: ['./challenge-step-upload.component.scss'],
})
export class ChallengeStepUploadComponent implements OnInit {
  uploadedFiles: any[] = [];
  workspace: WorkspaceDto;
  challenge: ChallengeDto;
  numberOfSelectedFiles = 0;
  // challengeId: string;
  uploading = false;
  mobile = false;
  basePath = environment.basePath;

  @ViewChild(FileUpload) fileUploader: FileUpload;

  @Input()
  uploadCode: string;
  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private workspaceDataService: WorkspaceDataService,
    private challengeDataService: ChallengeDataService
  ) {
    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
      });

    this.challengeDataService.challenge$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((challenge) => {
        this.challenge = challenge;
      });
  }

  ngOnInit() {
    this.mobile = window?.innerWidth <= 530;
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this), debounceTime(150))
      .subscribe(() => {
        this.mobile = window?.innerWidth <= 830;
      });
  }

  onUpload(event: FileUploadEvent) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  fileSelected(event: any) {
    this.numberOfSelectedFiles = event.files.length;
  }

  upload() {
    this.uploading = true;
    this.fileUploader.upload();
    this.fileUploader.onUpload
      .pipe(untilDestroyed(this))
      .subscribe(async (value) => {
        await this.onSubmit();
        this.workspaceDataService.contractUrl = (<HttpResponse<any>>(
          value.originalEvent
        )).body.contractFile.url;
      });
  }

  async onSubmit() {
    this.uploading = false;
    this.next.emit('');
  }
}
