import { Injectable } from '@angular/core';
import { ChallengeDto } from '@sweetpopcorn/api-connector';
import { BehaviorSubject, filter, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChallengeDataService {
  private challenge: Subject<ChallengeDto> = new BehaviorSubject<ChallengeDto>(
    null
  );

  get challenge$() {
    return this.challenge
      .asObservable()
      .pipe(filter((challenge) => !!challenge));
  }

  setChallenge(challenge: ChallengeDto) {
    this.challenge.next(challenge);
  }
}
