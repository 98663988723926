/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SubscriptionStatus {
    Unknown = 'Unknown',
    SessionPending = 'SessionPending',
    SessionExpired = 'SessionExpired',
    Incomplete = 'Incomplete',
    IncompleteExpired = 'IncompleteExpired',
    Trialing = 'Trialing',
    Active = 'Active',
    PastDue = 'PastDue',
    Canceled = 'Canceled',
    Unpaid = 'Unpaid'
};

