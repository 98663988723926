<ng-container *transloco="let t">
  <div class="flex flex-column w-full p-0 md:p-4">
    <div class="flex flex-column gap-4">
      <p-panel [header]="t('default-contract-title')">
        <p-scrollPanel
          [style]="{ width: '100%', height: '300px' }"
          styleClass="custombar1"
        >
          <div *ngIf="workspace?.firstName">
            {{
              t('default-contract-header1', {
                firstname: workspace?.firstName,
                lastname: workspace?.lastName,
                geb: workspace?.dateOfBirth,
                adress: workspace?.addressLine1,
                plz: workspace?.postalCode,
                city: workspace?.city,
                country: workspace?.country
              })
            }}
          </div>
          <div *ngIf="!workspace?.firstName">
            {{
              t('default-contract-header2', {
                companyname: workspace?.companyName,
                vat: workspace?.companyVat,
                adress: workspace?.addressLine1,
                plz: workspace?.postalCode,
                city: workspace?.city,
                country: lang === 'de' ? 'Österreich' : 'Austria'
              })
            }}
          </div>
          <p
            [innerHTML]="
              t('default-contract-text1', {
                actualMonth: actualMonth,
                defaultPrize:
                  lang === 'de'
                    ? workspace?.prize?.title
                    : workspace?.prize?.titleEn
              })
            "
          ></p>
          {{
            workspace?.uploadLimit
              ? t('contract-' + workspace?.uploadLimit)
              : t('contract-NoLimit')
          }}
          <p
            [innerHTML]="t('default-contract-text2', { city: workspace?.city })"
          ></p>
        </p-scrollPanel>
      </p-panel>
    </div>

    <form [formGroup]="form">
      <div class="mt-4">
        <!--        <button-->
        <!--          pButton-->
        <!--          *ngIf="!form.get('agbAccepted1').value"-->
        <!--          (click)="form.get('agbAccepted1').setValue(true)"-->
        <!--          class="w-full flex justify-content-center"-->
        <!--        >-->
        <!--          {{ t('contract-btn1') }}-->
        <!--        </button>-->
        <button
          pButton
          class="w-full flex justify-content-center"
          (click)="form.get('agbAccepted2').setValue(true); onSubmit()"
        >
          {{ t('contract-btn2') }}
        </button>
      </div>
    </form>

    <!--    <div class="flex flex-column gap-4 mt-4 mb-4 p-4">-->
    <!--      <button-->
    <!--        pButton-->
    <!--        pRipple-->
    <!--        label="Next"-->
    <!--        class="w-auto"-->
    <!--        [disabled]="!form.valid"-->
    <!--        (click)="onSubmit()"-->
    <!--      ></button>-->
    <!--    </div>-->
  </div>
</ng-container>
