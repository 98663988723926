/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DomainEvent } from './domain-event';


export interface Country { 
    id?: string;
    readonly domainEvents?: Array<DomainEvent> | null;
    nameDe?: string | null;
    nameEn?: string | null;
    countryCode?: string | null;
    canBuy?: boolean;
}

