/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChallengeDto } from './challenge-dto';


export interface ChallengeDtoPaginatedList { 
    items?: Array<ChallengeDto> | null;
    pageNumber?: number;
    readonly totalPages?: number;
    readonly totalCount?: number;
    readonly hasPreviousPage?: boolean;
    readonly hasNextPage?: boolean;
}

