<ng-container *transloco="let t">
  <div
    class="flex flex-column justify-content-center align-content-center align-items-center w-full mt-3"
  >
    <div
      class="flex flex-column w-11 md:w-10 p-0 sm:p-4 surface-card shadow-2 border-round"
    >
      <div class="flex flex-row justify-content-end w-full pt-2 pr-2">
        <sweetpopcorn-language-selector></sweetpopcorn-language-selector>
      </div>
      <div class="flex flex-row justify-content-center p-4">
        <img
          *ngIf="workspace?.file?.url; else defaultImage"
          [src]="workspace?.file?.url"
          alt="uplaod-form-header"
          class="w-full md:w-4 xl:w-4"
        />
        <ng-template #defaultImage>
          <img
            src="./assets/images/Upload.png"
            alt="uplaod-form-header"
            class="w-4"
          />
        </ng-template>
      </div>

      <div class="card">
        <ng-container [formGroup]="wizardForm">
          <p-tabView styleClass="tabview-custom" [(activeIndex)]="index">
            <p-tabPanel [disabled]="true">
              <ng-template pTemplate="header">
                <span>{{ t('step2-name') }}</span>
              </ng-template>
              <sweetpopcorn-upload-step-legal
                formGroupName="legal"
                (next)="onNext($event)"
              ></sweetpopcorn-upload-step-legal>
            </p-tabPanel>
            <p-tabPanel header="Header II" [disabled]="true">
              <ng-template pTemplate="header">
                <span>{{ t('step1-name') }}</span>
              </ng-template>
              <sweetpopcorn-upload-step-info
                formGroupName="info"
                (next)="onNext($event)"
              ></sweetpopcorn-upload-step-info>
            </p-tabPanel>
            <p-tabPanel header="Header III" [disabled]="true">
              <ng-template pTemplate="header">
                <span>{{ t('step3-name') }}</span>
              </ng-template>
              <sweetpopcorn-upload-step-upload
                [uploadCode]="uploadCode"
                (next)="onNext($event)"
              ></sweetpopcorn-upload-step-upload>
            </p-tabPanel>
            <p-tabPanel header="Header IIII" [disabled]="true">
              <ng-template pTemplate="header">
                <span>{{ t('step4-name') }}</span>
              </ng-template>
              <sweetpopcorn-upload-step-contract
                (next)="onNext($event)"
                [workspaceId]="workspace?.slug"
              ></sweetpopcorn-upload-step-contract>
            </p-tabPanel>
          </p-tabView>
        </ng-container>
      </div>
    </div>
  </div>
  <sweetpopcorn-footer></sweetpopcorn-footer>
</ng-container>
