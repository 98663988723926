/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkspaceRole } from './workspace-role';


export interface WorkspaceUserDto { 
    id?: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    role?: WorkspaceRole;
}

