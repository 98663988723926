import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'sweetpopcorn-not-found',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterLink,
    FooterComponent,
    TranslocoDirective,
  ],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  reason$: Observable<string> = new Observable<string>();
  constructor(private titleService: Title, private route: ActivatedRoute) {}

  ngOnInit() {
    this.reason$ = this.route.queryParamMap.pipe(
      map((paramMap) => paramMap.get('reason')),
      distinctUntilChanged()
    );
    this.titleService.setTitle(`Oops!`);
  }
}
