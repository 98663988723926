/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { Language } from './language';


export interface SubmissionDto { 
    id?: string;
    challengeId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    customerReference?: string | null;
    dateOfBirth?: string;
    language?: Language;
    contractFile?: FileDto;
    workspaceId?: string;
    itemCount?: number;
    isWinningSubmission?: boolean;
    voucherIsUsed?: boolean;
    consentGivenOn?: string;
    createdOn?: string;
    lastModifiedOn?: string | null;
}

