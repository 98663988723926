<ng-container *transloco="let t">
  <div class="flex flex-column w-full">
    <div class="flex flex-column gap-3 mt-4">
      <div class="text-2xl text-center">{{ t('title-shareChallenge') }}</div>
      <div class="text-center">
        {{ lang === 'de' ? challenge?.description : challenge?.descriptionEn }}
      </div>
      <div class="flex flex-row justify-content-center">
        <div class="font-bold">{{ t('label-mainPrize') }}:&nbsp;</div>
        <div>
          {{
            lang === 'de' ? challenge?.prize?.title : challenge?.prize?.titleEn
          }}
        </div>
      </div>
      <div class="flex flex-column align-items-center gap-2">
        <div class="font-bold">{{ t('label-deadline') }}:&nbsp;</div>
        <div>
          {{ challenge?.submissionEndTime | date : 'dd.MM.yyyy HH:mm' }}
        </div>
      </div>
      <div class="text-500 font-medium text-center">
        {{ t('subtitle-shareChallenge') }}
      </div>
      <div class="text-center">
        {{ lang === 'de' ? workspace.prize.title : workspace.prize.titleEn }}
      </div>
      <div>
        <div class="flex flex-column gap-4 mt-2">
          <button
            (click)="onSubmit()"
            pButton
            pRipple
            label="{{ t('btn-share') }}"
            class="w-auto"
          ></button>
        </div>
        <div class="flex flex-row justify-content-center mt-2">
          <div class="text-500 text-xs md:text-base">
            {{ workspace?.name }} |
          </div>
          <div
            class="text-500 text-xs md:text-base cursor-pointer"
            (click)="mailTo(workspace?.contactMail)"
          >
            &nbsp;{{ workspace?.contactMail }} &nbsp;
          </div>
          <div
            class="text-500 text-xs md:text-base cursor-pointer"
            *ngIf="workspace?.contactPhone"
            (click)="phoneTo(workspace?.contactPhone)"
          >
            | {{ workspace?.contactPhone }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
