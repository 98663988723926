<ng-container *transloco="let t">
  <div class="flex flex-column w-full p-4">
    <div class="mb-4">
      {{ t('step3-desc') }}
    </div>
    <label for="uploadLimit">{{ t('label-uploadNumber') }}:</label>
    <span id="uploadLimit"
      >{{ fileUpload._files.length }}/{{ challenge.maxSubmissionSize }}</span
    >
    <p-fileUpload
      #fileUpload
      name="files"
      accept="image/*, video/*"
      url="{{ basePath }}/api/submissions/{{ this.uploadCode }}/upload"
      (onUpload)="onUpload($event)"
      [cancelLabel]="mobile ? ' ' : t('btn-clear')"
      [chooseLabel]="mobile ? ' ' : t('btn-upload')"
      [multiple]="true"
      [showUploadButton]="false"
      [maxFileSize]="challenge.maxFileSize * 1000000"
      [invalidFileLimitMessageSummary]="t('error-maxFiles')"
      [invalidFileLimitMessageDetail]="''"
      [fileLimit]="challenge.maxSubmissionSize"
      [invalidFileSizeMessageSummary]="'{0}: ' + t('error-fileSize')"
      [invalidFileSizeMessageDetail]="''"
      [invalidFileTypeMessageSummary]="'{0}: ' + t('error-fileType')"
      [invalidFileTypeMessageDetail]="t('detail-fileType') + ' {0}.'"
      class="justify-center"
    >
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">
            {{ file.name }} - {{ file.size }} bytes
          </li>
        </ul>
      </ng-template>
    </p-fileUpload>

    <div class="flex flex-column gap-4 mt-4 mb-4 p-4">
      <button
        pButton
        pRipple
        label="{{ t('btn-next') }}"
        class="w-auto"
        [disabled]="
          uploading ||
          fileUpload._files.length <= 0 ||
          fileUpload._files.length > challenge.maxSubmissionSize ||
          fileUpload.isFileLimitExceeded()
        "
        (click)="upload()"
      ></button>
    </div>
  </div>
</ng-container>
