import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { VoucherDto, VoucherService } from '@sweetpopcorn/api-connector';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { switchMap } from 'rxjs';

@Component({
  selector: 'sweetpopcorn-voucher-devalue',
  templateUrl: './voucher-devalue.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    CardModule,
    DragDropModule,
    NgIf,
    DatePipe,
    ScrollPanelModule,
  ],
  styleUrls: ['./voucher-devalue.component.scss'],
})
export class VoucherDevalueComponent {
  @ViewChild('background') button_background: ElementRef;
  @ViewChild('slider') slider: ElementRef;
  @ViewChild('lock') lock: ElementRef;

  voucher: VoucherDto;
  lang = 'de';
  voucherCode = '';
  valid = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private voucherService: VoucherService,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.translocoService.langChanges$
      .pipe(
        switchMap(() => this.translocoService.selectTranslate('label-name'))
      )
      .subscribe(() => this.setLanguage());
    this.activatedRoute.queryParams
      .pipe(
        switchMap((query) => {
          this.voucherCode = query['code'];

          return this.voucherService.checkVoucher(this.voucherCode);
        })
      )
      .subscribe({
        next: (result) => {
          this.voucher = result;
          if (result.validatedOn === null) {
            this.valid = true;
          }
        },
        error: (error) => {
          console.error('Error in get voucher data');
          this.router.navigate(['invalidCode'], {
            relativeTo: this.activatedRoute.parent,
          });
        },
      });
  }

  initialMouse = 0;
  slideMovementTotal = 0;
  mouseIsDown = false;
  unlocked = false;

  unlock(event: any) {
    this.mouseIsDown = true;
    this.slideMovementTotal =
      this.button_background.nativeElement.clientWidth -
      this.slider.nativeElement.clientWidth +
      10;
    this.initialMouse = event.clientX || event.originalEvent.touches[0].pageX;
  }

  finished(event) {
    if (!this.mouseIsDown) return;
    this.mouseIsDown = false;
    const currentMouse = event.clientX || event.changedTouches[0].pageX;
    const relativeMouse = currentMouse - this.initialMouse;

    if (relativeMouse < this.slideMovementTotal - 5) {
      // $('.slide-text').fadeTo(300, 1);
      const animation = this.slider.nativeElement.animate(
        [
          { left: relativeMouse - 10 + 'px' }, // Startwert (optional, wenn das Element bereits positioniert ist)
          { left: '-10px' },
        ],
        {
          duration: 300,
          delay: 0,
          fill: 'forwards',
        }
      );

      animation.finished.then(() => {
        this.slider.nativeElement.style.left = '-10px';
        animation.cancel();
      });

      return;
    }
    this.slider.nativeElement.classList.add('unlocked');
    this.lock.nativeElement.classList.remove('pi-angle-right');
    this.lock.nativeElement.classList.add('pi-check');
    this.unlocked = true;
    this.submit();
  }

  mouseMove(event) {
    if (!this.mouseIsDown) return;

    const currentMouse = event.clientX || event.originalEvent.touches[0].pageX;
    const relativeMouse = currentMouse - this.initialMouse;
    const slidePercent = 1 - relativeMouse / this.slideMovementTotal;

    if (relativeMouse <= 0) {
      this.slider.nativeElement.style.left = '-10px';
      return;
    }
    if (relativeMouse >= this.slideMovementTotal + 10) {
      this.slider.nativeElement.style.left = this.slideMovementTotal + 'px';
      return;
    }
    this.slider.nativeElement.style.left = relativeMouse - 10 + 'px';
  }

  touchMove(event) {
    event.preventDefault();
    if (!this.mouseIsDown) return;

    const currentMouse = event?.touches[0].clientX;
    const relativeMouse = currentMouse - this.initialMouse;
    const slidePercent = 1 - relativeMouse / this.slideMovementTotal;

    if (relativeMouse <= 0) {
      this.slider.nativeElement.style.left = '-10px';
      return;
    }
    if (relativeMouse >= this.slideMovementTotal + 10) {
      this.slider.nativeElement.style.left = this.slideMovementTotal + 'px';
      return;
    }
    this.slider.nativeElement.style.left = relativeMouse - 10 + 'px';
  }

  setLanguage() {
    this.lang = this.translocoService.getActiveLang();
  }

  submit() {
    if (this.unlocked) {
      this.voucherService.redeemVoucher(this.voucherCode).subscribe((value) => {
        this.router.navigate(['redeemed'], {
          relativeTo: this.activatedRoute.parent,
        });
      });
    }
  }
}
