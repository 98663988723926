import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ChallengeOverviewComponent } from './challenge/challenge-overview/challenge-overview.component';
@Component({
  imports: [ChallengeOverviewComponent, RouterOutlet, TranslocoModule],
  selector: 'sweetpopcorn-root',
  standalone: true,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'PiViDro-Upload';
}
