import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'sweetpopcorn-redeemed',
  standalone: true,
  templateUrl: './redeemed.component.html',
  styleUrls: ['./redeemed.component.scss'],
  imports: [CardModule, DatePipe, TranslocoDirective],
})
export class RedeemedComponent {}
