/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubmissionWithCodeDto { 
    id?: string;
    challengeId?: string | null;
    code?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    customerReference?: string | null;
    workspaceId?: string;
    itemCount?: number;
    voucherIsUsed?: boolean;
    consentGivenOn?: string;
    createdOn?: string;
    lastModifiedOn?: string;
}

