<ng-container *transloco="let t">
  <div class="flex flex-column">
    <div class="relative">
      <div class="flex bg-gray-800" style="min-height: 66px">
        <div class="flex align-items-center w-8 md:w-10">
          <a
            class="flex cursor-pointer px-6 align-items-center hover:bg-gray-500 transition-colors transition-duration-150 text-3xl no-underline"
          >
            <img
              src="assets/images/PiViDro_Dark.svg"
              alt="pividro-logo"
              class="h-full w-8rem"
            />
          </a>
        </div>

        <div class="flex justify-center align-items-center text-white">
          <sweetpopcorn-language-selector></sweetpopcorn-language-selector>
        </div>
      </div>

      <div class="surface-section px-4 py-8 md:px-6 lg:px-8 overflow-hidden">
        <div class="font-bold text-900 text-3xl mb-3 text-center">
          {{ t('landing-title') }}
        </div>
        <div class="text-700 text-center mb-5 line-height-3">
          {{ t('landing-subtitle') }}
        </div>
        <div class="flex lg:justify-content-center mb-5">
          <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
            <img src="assets/images/Join.png" alt="Image" class="w-full mr-8" />
          </div>
          <div class="flex flex-column align-items-center w-2rem">
            <span
              class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle"
              style="min-width: 2.5rem; min-height: 2.5rem"
              >1</span
            >
            <div
              class="h-full bg-blue-500"
              style="width: 2px; min-height: 4rem"
            ></div>
          </div>
          <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div class="text-900 text-xl mb-2 font-medium">
              {{ t('landing-step1-title') }}
            </div>
            <span class="block text-700 line-height-3 mb-3">{{
              t('landing-step1-desc')
            }}</span>
            <div class="pt-3 border-top-1 border-300">
              <div class="mb-2 line-height-3">
                {{ t('landing-step1-work') }}
              </div>
              <div class="line-height-3">
                {{ t('landing-step1-challenge') }}
              </div>
            </div>
            <img
              src="assets/images/Join.png"
              alt="Image"
              class="w-full mt-3 block lg:hidden"
            />
          </div>
        </div>
        <div class="flex justify-content-center mb-5">
          <div
            class="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0"
          >
            <div class="text-900 text-xl mb-2 font-medium">
              {{ t('landing-step2-title') }}
            </div>
            <span class="block text-700 line-height-3 mb-3">{{
              t('landing-step2-desc')
            }}</span>
            <div class="pt-3 border-top-1 border-300">
              <div class="mb-2 line-height-3">
                {{ t('landing-step2-userdata') }}
              </div>
              <div class="line-height-3">
                {{ t('landing-step2-rights') }}
              </div>
            </div>
            <img
              src="assets/images/Upload.png"
              alt="Image"
              class="w-full mt-3 block lg:hidden"
            />
          </div>
          <div
            class="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1"
          >
            <span
              class="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
              style="min-width: 2.5rem; min-height: 2.5rem"
              >2</span
            >
            <div
              class="h-full bg-yellow-500"
              style="width: 2px; min-height: 4rem"
            ></div>
          </div>
          <div class="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
            <img
              src="assets/images/Upload.png"
              alt="Image"
              class="w-full mr-8"
            />
          </div>
        </div>
        <div class="flex justify-content-center">
          <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
            <img
              src="assets/images/Winners.png"
              alt="Image"
              class="w-full mr-8"
            />
          </div>
          <div class="flex flex-column align-items-center w-2rem">
            <span
              class="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
              style="min-width: 2.5rem; min-height: 2.5rem"
              >3</span
            >
            <div
              class="h-full bg-cyan-500"
              style="width: 2px; min-height: 4rem"
            ></div>
          </div>
          <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div class="text-900 text-xl mb-2 font-medium">
              {{ t('landing-step3-title') }}
            </div>
            {{ t('landing-step3-desc') }}
            <div class="pt-3 border-top-1 border-300">
              <div class="mb-2 line-height-3">
                {{ t('landing-step3-default') }}
              </div>
              <div class="line-height-3">
                {{ t('landing-step3-big') }}
              </div>
            </div>
            <img
              src="assets/images/Winners.png"
              alt="Image"
              class="w-full mt-3 block lg:hidden"
            />
          </div>
        </div>
      </div>

      <div class="surface-section px-4 md:px-6 lg:px-8">
        <div
          class="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between"
        >
          <div>
            <!--      <img src="assets/images/blocks/logos/bastion-700.svg" alt="Image" height="40">-->
            <div class="mt-2 line-height-3">
              &copy; 2023 Sweet Popcorn Studio GmbH, All rights reserved
            </div>
          </div>
          <div class="mt-3 sm:mt-0">
            <div>
              <a [routerLink]="'./impressum'">{{ t('impressum-title') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
