import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'sweetpopcorn-language-selector',
  standalone: true,
  imports: [CommonModule, DropdownModule, SharedModule, FormsModule],
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  selectedLanguage = 'en';

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.setDefaultLang();
  }

  setDefaultLang() {
    const _currentLang = this.translocoService.getActiveLang();
    this.selectedLanguage = _currentLang === 'de' ? 'en' : 'de';
  }

  updateLanguage(lang: string) {
    switch (lang) {
      case 'en':
        this.setLangauge('en');
        this.selectedLanguage = 'de';
        break;
      case 'de':
        this.setLangauge('de');
        this.selectedLanguage = 'en';
        break;
      default:
        this.setLangauge('de');
        this.selectedLanguage = 'en';
    }
  }

  setLangauge(lang: string) {
    this.translocoService.setActiveLang(lang);
    // this.findAndSetLang(lang);
  }
}
