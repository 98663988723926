<ng-container *transloco="let t">
  <div class="surface-card px-4 py-8 md:px-6 lg:px-8 shadow-2 border-round">
    <div
      class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7"
    >
      <div class="text-center lg:text-right">
        <div class="mt-6 mb-3 font-bold text-6xl text-900">
          {{ t('not-found-title') }}
        </div>
        <ng-container *ngIf="reason$ | async as reason">
          <div [ngSwitch]="reason">
            <div *ngSwitchCase="'unpaid'" class="text-700 text-3xl mt-0 mb-6">
              {{ t('not-found-body-unpaid') }}
            </div>

            <div
              *ngSwitchCase="'challenge-not-found'"
              class="text-700 text-3xl mt-0 mb-6"
            >
              {{ t('not-found-body-challenge') }}
            </div>

            <div
              *ngSwitchCase="'workspace-not-found'"
              class="text-700 text-3xl mt-0 mb-6"
            >
              {{ t('not-found-body-workspace') }}
            </div>

            <div *ngSwitchDefault class="text-700 text-3xl mt-0 mb-6">
              {{ t('not-found-body-default') }}
            </div>
          </div>
        </ng-container>
        <button
          pButton
          type="button"
          [label]="t('not-found-home-button')"
          class="p-button-outlined"
          [routerLink]="['/']"
        ></button>
      </div>
      <div>
        <img
          src="assets/images/sad.png"
          alt="Image"
          class="w-full md:w-28rem"
        />
      </div>
    </div>
  </div>
</ng-container>
<sweetpopcorn-footer></sweetpopcorn-footer>
