import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChallengeDto, WorkspaceDto } from '@sweetpopcorn/api-connector';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { TagModule } from 'primeng/tag';
import { distinctUntilChanged } from 'rxjs';
import { ChallengeDataService } from '../../services/challenge-data.service';
import { WorkspaceDataService } from '../../services/workspace-data.service';

@UntilDestroy()
@Component({
  selector: 'sweetpopcorn-start-challenge',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    RippleModule,
    RouterLink,
    TagModule,
    TranslocoModule,
  ],
  templateUrl: './challenge-overview.component.html',
  styleUrls: ['./challenge-overview.component.scss'],
})
export class ChallengeOverviewComponent {
  challenge: ChallengeDto;
  workspace: WorkspaceDto;
  lang = '';

  @Output()
  next: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private challengeDataService: ChallengeDataService,
    private workspaceDataService: WorkspaceDataService,
    private translocoService: TranslocoService
  ) {
    this.challengeDataService.challenge$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((challenge) => {
        this.challenge = challenge;
      });

    this.workspaceDataService.workspace$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id;
        }),
        untilDestroyed(this)
      )
      .subscribe((workspace) => {
        this.workspace = workspace;
      });
    this.translocoService.langChanges$.subscribe((value) => {
      this.lang = value;
    });
  }

  mailTo(mail: string) {
    const email = 'mailto:' + mail; // add the links to body
    window.location.href = email;
  }
  phoneTo(phone: string) {
    const tel = 'tel:' + phone; // add the links to body
    window.location.href = tel;
  }

  async onSubmit() {
    this.next.emit('');
    // await this.router.navigate([
    //   '/',
    //   this.workspace.slug,
    //   'challenge',
    //   this.challenge.id,
    //   'info',
    // ]);
  }
}
