/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SetDefaultPrizeForWorkspaceCommand { 
    workspaceIdOrSlug?: string | null;
    prizeTitle?: string | null;
    prizeTitleEn?: string | null;
    prizeDescription?: string | null;
    prizeDescriptionEn?: string | null;
    prizeUrl?: string | null;
    imageId?: string;
}

