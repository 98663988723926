/**
 * DropSocial API
 * API for DropSocial.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UploadLimit } from './upload-limit';
import { Visibility } from './visibility';
import { Orientation } from './orientation';


export interface UpdateChallengeCommand { 
    title?: string | null;
    titleEn?: string | null;
    description?: string | null;
    descriptionEn?: string | null;
    visibility?: Visibility;
    isPhotoAllowed?: boolean;
    isVideoAllowed?: boolean;
    isAudioAllowed?: boolean;
    isTextAllowed?: boolean;
    uploadLimit?: UploadLimit;
    minTextLength?: number | null;
    maxTextLength?: number | null;
    minAudioVideoDuration?: string | null;
    maxAudioVideoDuration?: string | null;
    photoVideoOrientation?: Orientation;
    maxFileSize?: number | null;
    maxSubmissionSize?: number | null;
    imageId?: string | null;
    submissionStartTime?: string | null;
    submissionEndTime?: string;
    winningPrizeTitle?: string | null;
    winningPrizeTitleEn?: string | null;
    winningPrizeDescription?: string | null;
    winningPrizeDescriptionEn?: string | null;
    winningPrizeUrl?: string | null;
    winningPrizeImageId?: string | null;
}

